import React, { useEffect } from "react";
import Router from "./router";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// action
import { checkAutoLoginAction } from './store/actions/AuthActions';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(checkAutoLoginAction(navigate));
  }, []);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
