import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  Table,
  Badge,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import {
  Media,
  MediaGroup,
  MediaText,
  OverlineTitle,
  Image,
  Icon,
} from "../../../components";
import { getDispatchfileList } from "../../../services/DashboardService";

const DispatchFileList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getDispatchfileList()
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Card className="h-100">
        <Card.Body className="py-2 flex-grow-0">
          <div className="card-title-group">
            <div className="card-title">
              <h5 className="title">Recieve File List</h5>
            </div>
          </div>
        </Card.Body>
        <Table responsive className="table-middle mb-0">
          <thead className="table-light table-head-sm">
            <tr>
              <th className="tb-col">
                <OverlineTitle tag="span">Files</OverlineTitle>
              </th>
              <th className="tb-col tb-col-end tb-col-sm">
                <OverlineTitle tag="span">Barcode</OverlineTitle>
              </th>

              <th className="tb-col tb-col-end">
                <OverlineTitle tag="span">status</OverlineTitle>
              </th>
              <th className="tb-col tb-col-end">
                <OverlineTitle tag="span">Created Date</OverlineTitle>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  <div className="d-flex flex-column align-items-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="mb-2"
                    />
                    <span>Loading...</span>
                  </div>
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
              <td colSpan="4" className="text-center py-4">
                <div className="text-muted">
                  <Icon name="inbox" size="lg" className="mb-2" />
                  <p className="mb-0">No files available for Dispatch</p>
                </div>
              </td>
            </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="tb-col">
                    <MediaGroup>
                      <Media size="sm" shape="circle">
                        <Icon name="file-fill"></Icon>
                      </Media>
                      <MediaText>
                        <a href="#link" className="title">
                          {item?.name}
                        </a>
                        <span className="text smaller">
                          {item?.file_number}
                        </span>
                      </MediaText>
                    </MediaGroup>
                  </td>
                  <td className="tb-col tb-col-end tb-col-sm">
                    {item?.barcode_number}
                  </td>
                  <td className="tb-col tb-col-end">
                    {item?.status === "received" ? (
                      <span className={`badge text-bg-info-soft`}>Received</span>
                    ) : (
                      <span className={`badge text-bg-pink-soft`}>OUT</span>
                    )}
                  </td>
                  <td className="tb-col tb-col-end">
                    <span className="small">{item?.created_at}</span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default DispatchFileList;
