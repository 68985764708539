import { Link } from 'react-router-dom';
import logoSrc from '../../Images/InteliTrack-Logo.png'
function Logo() {
    return (
        <Link to="/" className="logo-link">
            <div className="logo-wrap">
                <img src={logoSrc} alt="" width={'100px'} style={{ marginRight: '0.6rem' }} />
            </div>
        </Link>
    )
}

export default Logo;
