import axiosInstance from './AxiosInstance';
const profileUrl = "api/profile"

export function getProfileApi() {
    return axiosInstance.get(profileUrl);
}

export function getProfilePhotoApi(data) {
    return axiosInstance.post(profileUrl,data);
}

export function changePasswordApi(data) {
    return axiosInstance.patch(profileUrl, data);
}