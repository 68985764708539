import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { Icon } from "../../../components";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const AverageFileClearanceTime = () => {
  // Sample data
  const data = {
    labels: ["MM", "Execution", "Finance", "ED Office"], // Department names
    datasets: [
      {
        label: "Average Clearance Time (Days)", // Dataset label
        data: [3, 4.5, 4, 4.2], // Average clearance time
        backgroundColor: "#5f38f9", // Bar color
        borderColor: "#1976D2", // Bar border color
        borderWidth: 1, // Border width
        borderRadius: 8, // Rounded corners for bars
        barPercentage: 0.8, // Adjust bar width
      },
    ],
  };

  // Chart options for styling
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
          },
          color: "#333", // Legend text color
        },
      },
      tooltip: {
        backgroundColor: "#000",
        titleColor: "#fff",
        bodyColor: "#fff",
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines on x-axis
        },
        ticks: {
          color: "#333", // X-axis label color
          font: {
            size: 12,
          },
        },
      },
      y: {
        beginAtZero: true, // Start y-axis at zero
        grid: {
          color: "#e0e0e0", // Grid line color
        },
        ticks: {
          color: "#333", // Y-axis label color
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Average Clearance Time (Days)", // Y-axis title
          color: "#333",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        <div className="card-title">
          <h4 className="title">Average File Clearance Time</h4>
        </div>
        <div className="d-flex justify-content-between align-items-end gap g-2">
          <div className="flex-grow-1">
            <div className="smaller">
              <strong className="text-base">48%</strong> new file
              <span className="d-block">this week.</span>
            </div>
            <div className="d-flex align-items-center mt-1">
              <div className="amount h2 mb-0 fw-bold">16,328</div>
              <div className="change up smaller ms-1">
                <Icon name="trend-up"></Icon> 48
              </div>
            </div>
          </div>
        </div>
          <div className="nk-chart-ecommerce-visitor">
            <Bar data={data} options={options} />
          </div>
        {/* <div>
          <h3 style={{ fontFamily: "Arial, sans-serif", color: "#333" }}>
            Average File Clearance Time
          </h3>
          <Bar data={data} options={options} />
        </div> */}
      </Card.Body>
    </Card>
  );
};

export default AverageFileClearanceTime;
