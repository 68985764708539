import React from 'react'

export const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "management", label: "Management" },
    { value: "user", label: "User" },
    { value: "daak", label: "Daak" },
];


export const deptOptions = [
    { value: "MM", label: "Materials Management" },
    { value: "Execution", label: "Execution" },
    { value: "Finance", label: "Finance" },
    { value: "ED", label: "ED (Office)" },
];