import { useState } from 'react';
import classNames from 'classnames';
import { Dropdown, Button, Offcanvas, Alert } from 'react-bootstrap';

import { connect } from 'react-redux';
import { toInitials } from '../../../utilities';
import { Image, Icon, MediaAction, MediaGroup, MediaText, Media, LinkList, LinkListItem, CustomDropdownToggle, CustomDropdownMenu, Schedule } from '../../../components';
import ToggleSidebar from '../Toggle/Sidebar'
// import ToggleNavbar from '../Toggle/Navbar'
import LogoutPage from './Logout';
import Notification from './Notification';

import { useLayout, useLayoutUpdate } from './../LayoutProvider'

import { useNavigate } from 'react-router-dom';
import ReceiveFile from '../../../pages/File/Movement/Global/ReceiveFile/ReceiveFile';
import DispatchFile from '../../../pages/File/Movement/Global/DispatchFile/DispatchFile';


function QuickNav({ className, ...props }) {
    const compClass = classNames({
        "nk-quick-nav": true,
        [className]: className,
    })
    return (
        <ul className={compClass}>{props.children}</ul>
    )
}

function QuickNavItem({ className, ...props }) {
    const compClass = classNames({
        "d-inline-flex": true,
        [className]: className,
    })
    return (
        <li className={compClass}>{props.children}</li>
    )
}


function Header({ user }) {

    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const layout = useLayout();
    const layoutUpdate = useLayoutUpdate();

    const compClass = classNames({
        "nk-header nk-header-fixed": true,
        [`is-${layout.headerVariant}`]: layout.headerVariant,
    });
    const handleClick = () => {
        setShow(false);
    }


    const navClass = classNames({
        "nk-header-menu nk-navbar": true,
        "navbar-active": layout.headerActive,
        // eslint-disable-next-line
        "navbar-mobile": layout.headerTransition || eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
    });


    return (
        <>
            <div className={compClass}>
                <div className="container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-header-logo">
                            <ToggleSidebar variant="zoom" icon='menu' />


                        </div>
                        {layout.headerActive && <div className="navbar-overlay" onClick={layoutUpdate.headerMobile}></div>}
                        {/* <nav className={navClass}>
                    <Menu />
                </nav> */}

                        {/* quick action button */}

                        <div>

                        </div>
                        <div>
                            {user?.role === "daak" && <div>
                                <ReceiveFile />
                                <span className='mx-3'></span>
                                <DispatchFile />
                            </div>}
                        </div>
                        <div className="nk-header-tools">
                            <QuickNav>

                                <Dropdown as={QuickNavItem}>

                                    <Dropdown.Toggle variant="zoom" size="md" bsPrefix className="btn-icon d-none d-sm-inline-flex">
                                        <Icon name="search"></Icon>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-lg">
                                        <div className="dropdown-content dropdown-content-x-lg py-1">
                                            <div className="search-inline">
                                                <div className="form-control-wrap flex-grow-1">
                                                    <input placeholder="Type Query" type="text" className="form-control-plaintext" />
                                                </div>
                                                <Icon name="search" size="sm"></Icon>
                                            </div>
                                        </div>
                                        <Dropdown.Divider className="m-0"></Dropdown.Divider>
                                        <div className="dropdown-content dropdown-content-x-lg py-3">
                                            <div className="dropdown-title pb-2">
                                                <h5 className="title">Recent searches</h5>
                                            </div>
                                            <ul className="dropdown-list gap gy-2">
                                                <li>
                                                    <MediaGroup>
                                                        <Media size="md" shape="circle" variant="light"><Icon name="clock"></Icon></Media>
                                                        <MediaText>
                                                            <div className="lead-text">Styled Doughnut Chart</div>
                                                            <span className="sub-text">1 days ago</span>
                                                        </MediaText>
                                                        <MediaAction end>
                                                            <Button size="md" variant="zoom" className="btn-icon me-n1"><Icon name="trash"></Icon></Button>
                                                        </MediaAction>
                                                    </MediaGroup>
                                                </li>
                                                <li>
                                                    <MediaGroup>
                                                        <Media size="md" shape="circle" variant="light"><Icon name="clock"></Icon></Media>
                                                        <MediaText>
                                                            <div className="lead-text">Custom Select Input</div>
                                                            <span className="sub-text">07 Aug</span>
                                                        </MediaText>
                                                        <MediaAction end>
                                                            <Button size="md" variant="zoom" className="btn-icon me-n1"><Icon name="trash"></Icon></Button>
                                                        </MediaAction>
                                                    </MediaGroup>
                                                </li>
                                                <li>
                                                    <MediaGroup>
                                                        <Media size="md" shape="circle" variant="light">
                                                            <Image src='/images/avatar/a.jpg' staticImage />
                                                        </Media>
                                                        <MediaText>
                                                            <div className="lead-text">Sharon Walker</div>
                                                            <span className="sub-text">Admin</span>
                                                        </MediaText>
                                                        <MediaAction end>
                                                            <Button size="md" variant="zoom" className="btn-icon me-n1"><Icon name="trash"></Icon></Button>
                                                        </MediaAction>
                                                    </MediaGroup>
                                                </li>
                                            </ul>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <QuickNavItem>
                                    <Notification />
                                </QuickNavItem>

                                <Dropdown as={QuickNavItem} show={show} onToggle={(isOpen) => setShow(isOpen)}>
                                    <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>
                                        <div className="d-inline-flex">
                                            <Media size="sm" shape="circle" variant="dark-soft">
                                                {user?.profile_photo ? (
                                                    <Image
                                                        src={`${process.env.REACT_APP_BASE_URL}${user?.profile_photo}`}
                                                        staticImage                                           
                                                        alt="user"
                                                    />
                                                ) : (
                                                    <span className="fw-medium">{toInitials(user?.name)}</span>
                                                )}
                                            </Media>

                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu}>
                                        <div className="dropdown-content dropdown-content-x-md py-1 border-bottom border-light">
                                            <MediaGroup>
                                                <Media size="lg" shape="circle" variant="dark-soft">
                                                    {user?.profile_photo ? (
                                                        <Image
                                                            src={`${process.env.REACT_APP_BASE_URL}${user?.profile_photo}`}
                                                            staticImage
                                                            alt="user"
                                                        />
                                                    ) : (
                                                        <span className="fw-medium">{toInitials(user?.name)}</span>
                                                    )}
                                                </Media>

                                                <MediaText>
                                                    <div className="lead-text">{user?.name}</div>
                                                    <span className="sub-text text-uppercase">{user?.role}</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div className="dropdown-content dropdown-content-x-lg py-1 border-bottom border-light" onClick={handleClick} >
                                            <LinkList>
                                                <LinkListItem to="/profile">
                                                    <Icon name="user"></Icon>
                                                    <span>My Profile</span>
                                                </LinkListItem>
                                                <LinkListItem to="/account-settings">
                                                    <Icon name="setting-alt"></Icon>
                                                    <span>Account Settings</span>
                                                </LinkListItem>
                                            </LinkList>
                                        </div>
                                        <div className="dropdown-content dropdown-content-x-lg py-0" onClick={handleClick}>
                                            <LogoutPage />
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </QuickNav>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user || null
});

export default connect(mapStateToProps)(Header);