import React, { useState, useCallback, useEffect } from "react";
import { Colors } from "../../../utilities/index";
import { Card, Spinner } from "react-bootstrap";
import { Icon, Pureknob } from "../../../components";
import { getReceiveDispatchCount } from "../../../services/DashboardService";

const DispatchFileCount = () => {
  const [data, setData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getReceiveDispatchCount()
      .then((resp) => {
        const receivedFiles = Number(resp.data.to_dispatch) || 0;
        setData(receivedFiles);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let totalTaskDone = {
    size: 136,
    value: data,
    angleOffset: -0.5,
    angleStart: 0.7,
    angleEnd: 0.7,
    colorFg: Colors.white,
    trackWidth: "0.15",
  };
  return (
    <>
      <Card className="h-100 bg-success">
        <Card.Body>
        {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
          <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between gx-xl-5">
            <Pureknob data={totalTaskDone} className="nk-chart-project-done text-white">
              <span className="knob-title small text-white">Dispatch</span>
            </Pureknob>
            <div className="card-title mb-0 mt-4 mt-sm-0">
              <h5 className="title mb-3 mb-xl-5 text-white">Dispatch File</h5>
              <div className="amount h1 text-white"><Icon name="file-fill"></Icon> {data}</div>
              {/* <div className="d-flex align-items-center smaller flex-wrap">
                <div className="change up">
                  <Icon name="upword-alt-fill"></Icon> 14.2%
                </div>
                <span className="text-light">From last week</span>
              </div> */}
            </div>
          </div>
          </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default DispatchFileCount;
