import React from "react";
import { Card } from "react-bootstrap";
import { Icon, Media } from "../../../components";

const KPIData = () => {
  const data = [
    {
      title: "Total Approved",
      value: (
        <>
          120 <Icon name="thumbs-up" className="text-success ms-4" />
        </>
      ),
      icon: (
        <Media shape="circle" variant="success" className="mb-3 me-3">
          <Icon name="file" />
        </Media>
      ),
    },
    {
      title: "Pending for Approval",
      value: (
        <>
          35 <Icon name="info-fill" className="text-danger ms-4" />
        </>
      ),
      icon: (
        <Media shape="circle" variant="danger" className="mb-3 me-3">
          <Icon name="clock" />
        </Media>
      ),
    },
    {
      title: "Intra vs Outside Files",
      value: (
        <>
          80/40<Icon name="activity" className="text-info ms-3" />
        </>
      ),
      icon: (
        <Media shape="circle" variant="info" className="mb-3 me-3">
        <Icon name="line-chart" />
        </Media>
      ),
    },
  ];

  return (
    <>
      {data.map((item, index) => (
        <Card key={index} className="shadow-sm mb-2">
          <Card.Body className="d-flex align-items-center">
            {item.icon}
            <div>
              <Card.Title className="fs-6">{item.title}</Card.Title>
              <Card.Text className="fs-6">{item.value}</Card.Text>
            </div>
          </Card.Body>
        </Card>
      ))}
    </>
  );
};

export default KPIData;
