import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { searchMoveUserApi, sendFileApi } from "../../../services/FileService";
import { Badge, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { StaticSelectInput } from "../../Constant/SelectInput";
import { Icon } from "../../../components";

function MoveFileModal({ data }) {
  const [formData, setFormData] = useState({
    comments: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [approveUserList, setApproveUserList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!isModalVisible || data?.last_tracking?.action === "approval") return; // Skip fetching if action is "approval"

      try {
        const approveResponse = await searchMoveUserApi();
        setApproveUserList(approveResponse.data?.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data.");
      }
    };

    fetchData();
  }, [isModalVisible, data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      id: data?.id || "",
      comments: formData.comments,
    };

    if (data?.last_tracking?.action !== "approval") {
      payload.due_date = formData.due_date;
      payload.approver_id = formData.approver_id;
    }

    try {
      setIsSubmitLoading(true);
      const response = await sendFileApi(payload);
      setErrors({});
      toast.success(response.data?.message);
      setIsModalVisible(false);
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Badge
        bg="danger"
        onClick={() => setIsModalVisible(true)}
        style={{ cursor: "pointer" }}
      >
        Move File <Icon name="arrow-up-right" />
      </Badge>
      <Modal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Move File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="g-gs mb-4">
            {data?.last_tracking?.action === "approval" && <div className="text-success">Moving File For Approval !</div>}
              {data?.last_tracking?.action !== "approval" && (
                <>
                  <Col lg="12">
                    <StaticSelectInput
                      setParentInputValue={(name, value) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          [name]: value,
                        }))
                      }
                      optionsList={approveUserList?.map((item) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      name="approver_id"
                      id="approver_id"
                      isRequired={true}
                      placeholder="Select Next officer"
                      label={"Next Officer"}
                    />
                  </Col>
                  <Col lg="12">
                    <Form.Group>
                      <Form.Label htmlFor="due_date">Due Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="due_date"
                        value={formData.due_date || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
              <Col lg="12">
                <Form.Group>
                  <Form.Label htmlFor="comments">
                    Comments (Optional)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="comments"
                    placeholder="Leave your comments"
                    value={formData.comments}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex gap-3">
              <Button
                type="submit"
                variant="success"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? "Moving..." : "Move"}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate("/files")}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(MoveFileModal);
