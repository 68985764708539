import React, { useState, useEffect, useCallback } from "react";
import { CancelRequest } from "../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Card, Modal, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import { getFileListApi } from "../../services/FileService";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";

function FilePage({ userRole }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  const [filter, setFilter] = useState({
    query: "",
    department: "",
    designation: "",
  });
  const [barcodeToShow, setBarcodeToShow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const recordsPage = 25;
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    // Fetch data here
    setIsLoading(true);
    getFileListApi(currentPage, recordsPage, filter.query)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.total);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, filter.query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleSearchChange = (value) => {
    if (value.length > 0) CancelRequest();
    setFilter((prev) => ({
      ...prev,
      query: value,
    }));
    setCurrentPage(1);
  };

  const openBarcodeModal = (barcodeImage) => {
    setBarcodeToShow(barcodeImage);
    setIsModalVisible(true);
  };

  // Print functionality
  const printBarcode = (barcodeImage) => {
    const printWindow = window.open("", "_blank", "width=600,height=400");
    printWindow.document.write(`
      <html>
        <head><title>Print Barcode</title></head>
        <body>
          <img src="${barcodeImage}" alt="Barcode" />
          <script>
            window.onload = function() {
              window.print();
            }
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const columns = [
    {
      name: "File Number",
      selector: (row) => {
        return (
          <span
            className="text-primary fw-bold"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/file/${row?.id}`)}
          >
            {row?.file_number}
          </span>
        );
      },
    },
    {
      name: "File Name",
      selector: (row) => {
        return <span>{row?.name}</span>
      },
    },
    {
      name: "File Source",
      selector: (row) => {
        return row?.is_outside ? <span className="badge text-bg-danger-soft">External</span> : <span className="badge text-bg-info-soft">Internal</span>
      },
    },
    {
      name: "Priority Level",
      selector: (row) => {
        return row?.priority_level === "high" ? (
          <span className="badge text-bg-danger-soft">High</span>
        ) : row?.priority_level === "medium" ? (
          <span className="badge text-bg-warning-soft">Medium</span>
        ) : (
          <span className="badge text-bg-info-soft">Low</span>
        );
      },
    },{
      name: "Status",
      selector: (row) => {
        return row?.status === "created" ? (
          <span className={`badge bg-warning`}>Created</span>
        ) : row?.status === "in_progress" ? (
          <span className={`badge bg-warning`}>In Progress</span>
        ) : row?.status === "approved" ? (
          <span className={`badge bg-primary`}>Approved</span>
        ) : row?.status === "completed" ? (
          <span className={`badge bg-success`}>Completed</span>
        ) : (
          <span className={`badge bg-info`}>Reopened</span>
        );
      },
    },
  ];

  const viewChange = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  return (
    <div className="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Files</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Files
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          {(userRole === "management" || userRole === "user") && (
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Link
                  to="/add-file"
                  className="btn btn-primary btn-md d-md-none"
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/add-file"
                  className="btn btn-primary d-none d-md-inline-flex"
                >
                  <Icon name="plus" />
                  <span>Add File</span>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
          )}
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card>
          <div className="data-table-wrapper">
            <div className="data-table-top">
              <div className="data-table-search">
                <input
                  className="form-control"
                  placeholder="Search by name"
                  type="text"
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div className="data-table-action-wrap">
                <div className="data-table-select">
                  <select
                    className="form-select"
                    onChange={(e) => setShowItemPerPage(e.target.value)}
                    value={showItemPerPage}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </select>
                  <span className="text">Per page</span>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={data}
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination
              progressPending={isLoading}
              progressComponent={
                <div className="text-center my-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              } 
              paginationComponent={({
                rowsPerPage,
                rowCount,
                onChangePage,
                onChangeRowsPerPage,
                currentPage,
              }) => (
                <div className="data-table-bottom">
                  <DataTablePagination
                    showItemPerPage={showItemPerPage}
                    itemPerPage={rowsPerPage}
                    totalItems={rowCount}
                    paginate={onChangePage}
                    currentPage={currentPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setShowItemPerPage={setShowItemPerPage}
                  />
                </div>
              )}
            />
          </div>
        </Card>
      </Block>

      {barcodeToShow && (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Barcode</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={barcodeToShow}
              alt="Barcode"
              style={{
                width: "100%",
                maxHeight: "300px",
                objectFit: "contain",
              }}
            />
            <div className="mt-3">
              <Button
                onClick={() => printBarcode(barcodeToShow)}
                variant="primary"
              >
                <Icon name="printer" className={`me-2`}></Icon>Barcode
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});
export default connect(mapStateToProps)(FilePage);
