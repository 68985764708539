import React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "../../components";
import { useNavigate } from "react-router-dom";

const Buttons = ({ icon, url }) => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        size="md"
        variant="zoom"
        className="btn-icon me-3 text-primary mb-1"
        onClick={() => navigate(url)}
      >
        <Icon name={icon} ></Icon>
      </Button>
    </>
  );
};

export default Buttons;
