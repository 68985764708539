import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  receiveFileDataApi,
  searchApproveUserApi,
  sendFileApi,
} from "../../../../../services/FileService";
import { Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { StaticSelectInput } from "../../../../Constant/SelectInput";
import { Icon } from "../../../../../components";
import Swal from 'sweetalert2/src/sweetalert2.js';

function ReceiveFileForm({ data, barcodeNumber, onResetStep, toggleModal }) {
  const [formData, setFormData] = useState({
    due_date: "",
    approver_id: "",
    comments: "",
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [approveUserList, setApproveUserList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const approveResponse = await searchApproveUserApi();
        setApproveUserList(approveResponse.data?.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.file && data.status === "out") {
      setFormData({
        due_date: data.file?.due_date || "",
        approver_id: data.file?.next_officer?.id || "",
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRadioChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      text: "The file has been successfully Out!!",
      timer: 3000,
      timerProgressBar: true,
    }).then(() => {
      console.log("SweetAlert closed");
    });
    toggleModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      barcode_number:barcodeNumber
    };

    try {
      setIsSubmitLoading(true);
      const response = await receiveFileDataApi(payload);
      setErrors({});
      toast.success(response.data?.message);
      alertSuccess();
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="g-gs mb-4">
          <Col lg="12" className="position-relative">
            <Form.Group>
              <Form.Label htmlFor="barcode_number">Enter Barcode Number</Form.Label>
              <Form.Control
                type="text"
                name="barcode_number"
                className="mb-1"
                id="barcode_number"
                value={barcodeNumber}
                disabled
              />
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 px-2"
                style={{ cursor: "pointer" }}
                onClick={onResetStep}
              >
                <Icon name="cross"></Icon>
              </span>
              <p className="text-success">Barcode successfully verified!</p>
              {/* Cross Icon */}
            </Form.Group>
          </Col>

          <Col lg="12">
            <StaticSelectInput
              setParentInputValue={handleRadioChange}
              optionsList={approveUserList.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              name="approver_id"
              id="approver_id"
              placeholder="Select Next Officer"
              label="Next Officer"
              isRequired
              isDisabled={data.status === "out" && data.file}
              defaultValue={{
                      label: data.file?.next_officer?.name,
                      value: data.file?.next_officer?.id,
                    }
              }
            />
          </Col>

          <Col lg="12">
            <Form.Group>
              <Form.Label htmlFor="due_date">Due Date</Form.Label>
              <Form.Control
                type="date"
                name="due_date"
                value={ formData.due_date}
                disabled={data.status === "out" && data.file}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col lg="12">
            <Form.Group>
              <Form.Label htmlFor="comments">Comments</Form.Label>
              <Form.Control
                as="textarea"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex gap-3">
          <Button type="submit" variant="success" size="sm" disabled={isSubmitLoading}>
            {isSubmitLoading ? "Sending..." : "Send"}
          </Button>
          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={onResetStep}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(ReceiveFileForm);
