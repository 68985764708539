import { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import Choices from "choices.js";

function Select({
  id,
  name,
  value,
  onChange,
  children,
  multiple = false,
  disabled = false,
  className = "",
  placeholder = "Select an option",
  removeItemButton = false,
  shouldSort = false,
  searchEnabled = true,
}) {
  const selectRef = useRef(null);
  const choicesInstance = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      choicesInstance.current = new Choices(selectRef.current, {
        silent: true,
        allowHTML: false,
        searchEnabled,
        placeholder: true,
        placeholderValue: placeholder,
        shouldSort,
        removeItemButton,
        itemSelectText: "",
      });

      // Attach change listener
      selectRef.current.addEventListener("change", (e) => {
        const selectedValue = multiple
          ? Array.from(e.target.selectedOptions).map((option) => option.value)
          : e.target.value;
        onChange({ target: { name, value: selectedValue } });
      });
    }

    return () => {
      if (choicesInstance.current) {
        choicesInstance.current.destroy();
      }
    };
  }, []); // Initialize once

  // Update Choices.js when value changes
  useEffect(() => {
    if (choicesInstance.current) {
      if (multiple) {
        choicesInstance.current.clearChoices();
        choicesInstance.current.setChoices(
          Array.from(selectRef.current.options).map((option) => ({
            value: option.value,
            label: option.label,
            selected: value.includes(option.value),
          })),
          "value",
          "label",
          true
        );
      } else {
        choicesInstance.current.setChoiceByValue(value || "");
      }
    }
  }, [value, multiple]);

  return (
    <Form.Select
      id={id}
      name={name}
      className={className}
      ref={selectRef}
      disabled={disabled}
      multiple={multiple}
    >
      {children}
    </Form.Select>
  );
}


export default Select;
