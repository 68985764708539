import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  searchApproveUserApi,
  sendFileApi,
} from "../../../../../services/FileService";
import { Badge, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { StaticSelectInput } from "../../../../Constant/SelectInput";
import { Icon } from "../../../../../components";

function StatusInFiles({ data, barcodeNumber, onResetStep }) {
  const [formData, setFormData] = useState({
    due_date: "",
    out_user_id: "",
    comments: "",
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [approveUserList, setApproveUserList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const approveResponse = await searchApproveUserApi();
        setApproveUserList(approveResponse.data?.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data.");
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRadioChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      id: data?.id || "",
    };

    try {
      setIsSubmitLoading(true);
      const response = await sendFileApi(payload);
      setErrors({});
      toast.success(response.data?.message);
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="g-gs mb-4">
          <Col lg="12" className="position-relative">
            <Form.Group>
              <Form.Label htmlFor="barcode_number">
                Enter Barcode Number
              </Form.Label>
              <Form.Control
                type="text"
                name="barcode_number"
                className="mb-1"
                id="barcode_number"
                value={barcodeNumber}
                disabled
              />
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 px-2"
                style={{ cursor: "pointer" }}
                onClick={onResetStep}
              >
                <Icon name="cross"></Icon>
              </span>
              <p className="text-danger">
                This File didn't out from Department! <br /> Put below
                information for dispatch the file{" "}
              </p>
              {/* Cross Icon */}
            </Form.Group>
          </Col>
            <Row>
              <Col md="6">
                <h3 className="mb-2">Sender</h3>
                <Row className="g-gs mb-4">
                  <Col lg="12">
                    <Form.Group>
                      <Form.Label htmlFor="due_date">Due Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="due_date"
                        value={formData.due_date}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <StaticSelectInput
                      setParentInputValue={handleRadioChange}
                      optionsList={approveUserList.map((item) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      name="out_user_id"
                      id="out_user_id"
                      placeholder="Select Out Officer"
                      label="Out Officer"
                      isRequired
                    />
                  </Col>
                  <Col lg="12">
                    <StaticSelectInput
                      setParentInputValue={handleRadioChange}
                      optionsList={approveUserList?.map((item) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      name="approver_id"
                      id="approver_id"
                      placeholder="Select Next officer"
                      label={"Next Officer"}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Icon name="user-fill"></Icon>
                <h3 className="mb-2">Receiver</h3>
                <Row className="g-gs mb-4">
                  <Col lg="12">
                    <Form.Group>
                      <Form.Label htmlFor="due_date">Due Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="due_date"
                        value={formData.due_date}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <StaticSelectInput
                      setParentInputValue={handleRadioChange}
                      optionsList={approveUserList?.map((item) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      name="approver_id"
                      id="approver_id"
                      placeholder="Select Next officer"
                      label={"Next Officer"}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label htmlFor="comments">Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="comments"
                    value={formData.comments}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
        </Row>

        <div className="d-flex gap-3">
          <Button
            type="submit"
            variant="success"
            size="sm"
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? "Sending..." : "Send"}
          </Button>
          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={onResetStep}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(StatusInFiles);
