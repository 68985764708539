import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { dispatchFileApi } from "../../../../../services/FileService";
import { Badge, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Icon } from "../../../../../components";
import Swal from 'sweetalert2/src/sweetalert2.js';

function DispatchFile() {
  const [formData, setFormData] = useState({
    barcode_number: "",
  });
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
    setFormData({ barcode_number: "" });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear errors for the field
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      text: "The file has been successfully dispatched!",
      timer: 3000,
      timerProgressBar: true,
    }).then(() => {
      console.log("SweetAlert closed");
    });
    toggleModal();
  };

  const handleVerifyFile = async (e) => {
    e.preventDefault();

    if (!formData.barcode_number.trim()) {
      setErrors({ barcode_number: "Barcode number is required" });
      return;
    }

    const payload = {
      ...formData,
    };

    try {
      setIsSubmitLoading(true);
      const response = await dispatchFileApi(payload);
      setData(response.data.results);
      setErrors({});
      toast.success(response.data?.message);
      alertSuccess();
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      setErrors({ api: errorMessage });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Badge bg="success" onClick={toggleModal} style={{ cursor: "pointer" }}>
        Dispatch File <Icon name="curve-up-right"></Icon>
      </Badge>
      {isModalVisible && <div className="fade modal-backdrop show"></div>}
      <Modal
        show={isModalVisible}
        onHide={toggleModal}
        size="md"
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Dispatch File</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleVerifyFile}>
              <Row className="g-gs mb-4">
                <Col lg="12">
                  <Form.Group>
                    <Form.Label htmlFor="barcode_number">
                      Enter Barcode Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="barcode_number"
                      id="barcode_number"
                      value={formData.barcode_number}
                      onChange={handleChange}
                      isInvalid={!!errors.barcode_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.barcode_number}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex gap-3">
                <Button
                  type="submit"
                  variant="success"
                  size="sm"
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? "Verifying..." : "Verify"}
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
              </div>
            </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(DispatchFile);
