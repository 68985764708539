import axiosInstance from "./AxiosInstance";

//Management User Dashboard
export function getFileDistributionStatus() {
  return axiosInstance.get("api/file_distribution_status");
}
export function getDocumentStatusCount() {
  return axiosInstance.get("api/document_status_count");
}

export function getTEStatusCount() {
  return axiosInstance.get("api/te_status_count");
}

//Daak User Dashboard
export function getReceivefileList() {
  return axiosInstance.get("api/file_to_receive");
}

export function getDispatchfileList() {
  return axiosInstance.get("api/file_to_dispatch");
}

export function getReceiveDispatchCount() {
  return axiosInstance.get("api/receive_dispatch_count");
}
