import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Card, Nav, Spinner, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import Block from "../../components/Block/Block";
import { getFileApi } from "../../services/FileService";
import { Icon } from "../../components";
import MoveFileModal from "./Movement/MoveFile";
import CollectFile from "./Movement/CollectFile";
import Buttons from "../Constant/Buttons";
import FileLog from "./FileLog";
import ApproveFileModal from "./Movement/Approve";
import Clarification from "./Movement/Clarification";
import Escalate from "./Movement/Escalate";

function FileDetails({ user }) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState("");
  const barcodeRef = useRef(null);
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFileApi(id)
      .then((resp) => {
        setFile(resp.data.results);
      })
      .catch((error) => {
        console.error(
          "Error fetching file data:",
          error.response?.data?.message || error.message
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAfterFileCollect = () => {};

  const handlePrint = () => {
    const printArea = barcodeRef.current; // Get the barcode content
    const originalContent = document.body.innerHTML; // Store original page content

    // Replace the page content with the barcode content
    document.body.innerHTML = printArea.outerHTML;

    window.print(); // Trigger print dialog

    // Restore original page content
    document.body.innerHTML = originalContent;

    window.location.reload(); // Reload to reinitialize React
  };

  return (
    <div className="container">
      <Block.Head>
        <Block.HeadBetween className="">
          <Block.HeadContent>
            <div className="d-flex flex-column flex-md-row align-items-md-center">
              <div className="mt-3 mt-md-0 ms-md-3">
                <div className="d-flex align-items-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <Buttons icon="arrow-left" url={"/files"} />
                    <h2 className="title mb-1 me-3">{file?.name}</h2>
                  </div>
                </div>

                <ul className="nk-list-option pt-1 ms-2">
                  <li>
                    <Icon name="file"></Icon>
                    <span className="small">{file?.file_number}</span>
                  </li>
                  <li>
                    <Icon name="calendar"></Icon>
                    <span className="small">
                      {file?.expected_complete_date}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Block.HeadContent>

          <Block.HeadContent className={"flex-grow"}>
            <CollectFile
              file={file}
              user={user}
              handleAfterUpdate={handleAfterFileCollect}
            />
            <RenderNextAction file={file} user={user} />
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block className="mt-4">
        <Card className="card-gutter-md">
          <div className="card-row card-row-lg col-sep col-sep-lg">
            <div className="card-aside">
              <Card.Body>
                {isLoading ? (
                  <div className="text-center my-4">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <>
                    <div className="bio-block">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <h4 className="bio-block-title">Details</h4>
                        <ul className="gap g-2">
                          <li className="d-none d-md-block">
                            <Button
                              onClick={() => {
                                navigate(`/edit-file/${id}`, {
                                  state: { data: file },
                                });
                              }}
                              className="btn btn-soft btn-primary btn-sm"
                            >
                              <Icon name="edit"></Icon>
                              <span>Edit</span>
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <ul className="list-group list-group-borderless small">
                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            File Number:
                          </span>
                          <span className="text">{file?.file_number}</span>
                        </li>

                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            Status:
                          </span>
                          <span className="text">
                            {file?.status === "created" ? (
                              <span className={`badge text-bg-info-soft`}>
                                Created
                              </span>
                            ) : file?.status === "in_progress" ? (
                              <span className={`badge text-bg-warning-soft`}>
                                In Progress
                              </span>
                            ) : file?.status === "approved" ? (
                              <span className={`badge text-bg-success-soft`}>
                                Approved
                              </span>
                            ) : file?.status === "completed" ? (
                              <span className={`badge text-bg-success-soft`}>
                                Completed
                              </span>
                            ) : (
                              <span className={`badge text-bg-pink-soft`}>
                                Reopened
                              </span>
                            )}
                          </span>
                        </li>

                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            File Type:
                          </span>
                          <span className="text">{file?.file_type?.name}</span>
                        </li>
                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            Priority Level:
                          </span>
                          <span className="text">
                            {file?.priority_level === "high" ? (
                              <span className="badge text-bg-danger-soft">
                                High
                              </span>
                            ) : file?.priority_level === "medium" ? (
                              <span className="badge text-bg-warning-soft">
                                Medium
                              </span>
                            ) : (
                              <span className="badge text-bg-info-soft">
                                Low
                              </span>
                            )}
                          </span>
                        </li>
                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            File Source:
                          </span>
                          <span className="text">
                            {file?.is_outside ? (
                              <>
                                <span className="badge text-bg-danger-soft me-1">
                                  External
                                </span>{" "}
                                {file?.file_source}
                              </>
                            ) : (
                              <span className="badge text-bg-info-soft">
                                Internal
                              </span>
                            )}
                          </span>
                        </li>
                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            Description:
                          </span>
                          <span className="text">{file?.description}</span>
                        </li>
                      </ul>
                      <Alert variant={"warning"} className="mt-2">
                        <div className="">
                          <h6 className="mb-1 d-flex align-items-center">
                            Created By:
                          </h6>
                          <p className="text-muted smaller mb-1">
                            <Icon
                              name="user-fill"
                              className="me-2 text-secondary"
                            />
                            {file?.created_by?.name}
                          </p>
                          <p className="text-muted smaller mb-1">
                            <Icon
                              name="call-fill"
                              className="me-2 text-secondary"
                            />
                            {file?.created_by?.phone_number}
                          </p>

                          <p className="text-muted smaller mb-1">
                            <Icon
                              name="building"
                              className="me-2 text-secondary"
                            />
                            {file?.created_by?.department}
                          </p>
                          <p className="text-muted smaller mb-1">
                            <Icon
                              name="clock"
                              className="me-2 text-secondary"
                            />
                            {file?.created_by?.created_at}
                          </p>
                        </div>
                      </Alert>
                      <Alert variant={"primary"} className="mt-2">
                        <div className="d-flex justify-content-between align-items-center mb-2" ref={barcodeRef}>
                          <Barcode
                            value={file?.barcode_number || "000000"}
                            height={20}
                            width={1.5}
                          />
                          <button
                            className="btn btn-info btn-sm"
                            onClick={handlePrint}
                          >
                            Print
                          </button>
                        </div>
                      </Alert>
                    </div>
                  </>
                )}
              </Card.Body>
            </div>
            <div className="card-content col-sep flex-fill">
              <FileLog id={id} />
            </div>
          </div>
        </Card>
      </Block>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user || null,
});
export default connect(mapStateToProps)(FileDetails);

const RenderNextAction = ({ file, user }) => {
  if (!file) return null;

  const actions = {
    escalation: (data) => <Escalate data={data} />,
    approval: (data) => <ApproveFileModal data={data} />,
    clarification: (data) => <Clarification data={data} />,
    move: (data) => <MoveFileModal data={data} />,
  };

  const getActionItems = () => {
    if (file.status === "completed") return [];

    if (!file.last_tracking) {
      return user?.id === file?.created_by?.id ? [actions.move(file)] : [];
    }

    const { status, receiver, action } = file.last_tracking;

    if (status === "in" && user?.id === receiver?.id) {
      switch (action) {
        case "move":
          return [
            actions.move(file),
            actions.clarification(file),
            actions.escalation(file),
            actions.approval(file),
          ];
        case "clarification":
          return [actions.move(file)];
        case "escalation":
          return [
            actions.move(file),
            actions.clarification(file),
            actions.approval(file),
          ];
        default:
          return [actions.move(file)];
      }
    }

    return [];
  };

  const actionItems = getActionItems();

  return actionItems.length > 0 ? (
    <Alert variant="secondary">
      <div className="flex">
        <span className="me-1">Next Action</span>
        {actionItems.map((modal, index) => (
          <span key={index} className="mx-1">
            {modal}
          </span>
        ))}
      </div>
    </Alert>
  ) : null;
};
