import axiosInstance from './AxiosInstance';
const fileUrl = "api/file"

// file
export function getFileListApi(page, page_size, query) {
    const params = new URLSearchParams({
        page,
        page_size,
        query
    });
    return axiosInstance.get(`${fileUrl}?${params.toString()}`);
}

export function getFileApi(id) {
    return axiosInstance.get(`${fileUrl}/${id}`);
}

export function createFileApi(data) {
    return axiosInstance.post(fileUrl, data);
}

export function updateFileApi(data) {
    return axiosInstance.put(`${fileUrl}`, data);
}

export function sendFileApi(data) {
    return axiosInstance.post(`api/file_out`, data);
}

export function collectFileApi(data) {
    return axiosInstance.post(`api/file_collect`, data);
}

export function approveFileApi(data) {
    return axiosInstance.post(`api/file_approval`, data);

}

export function getFileLogApi(id) {
    return axiosInstance.get(`api/file_tracking_log/${id}`);
}

export function searchFileApi() {
    return axiosInstance.get("api/search_file_type");
}

export function searchApproveUserApi() {
    return axiosInstance.get("api/search_approver");
}

export function searchMoveUserApi() {
    return axiosInstance.get("api/search_move_user");
}

export function searchDaakUserApi() {
    return axiosInstance.get("api/search_daak");
}

export function recieveFileApi(barcode_number) {
    return axiosInstance.get(`api/file_receive?barcode_number=${barcode_number}`);
}

export function receiveFileDataApi(data) {
    return axiosInstance.post(`api/file_receive`, data);
}


export function dispatchFileApi(data) {
    return axiosInstance.post(`api/file_dispatch`, data);
}

























