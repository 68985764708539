import React, { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { collectFileApi } from '../../../services/FileService'
import { toast } from 'react-toastify'

const CollectFile = ({ file, user, handleAfterUpdate }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [comments, setComments] = useState("")
    const [isDisplayForm, setIsDisplayForm] = useState(false)
    if (!file || !file.last_tracking) {
        return null;
    }

    const { sender, created_at, action } = file.last_tracking
    const handleCollect = async () => {
        // Logic to collect the file
        setIsLoading(true)
        try {
            const payload = { id: file.id, comments }
            const resp = await collectFileApi(payload);
            const { results, message } = resp.data
            setComments("")
            handleAfterUpdate(results)
            toggleForm()
            toast.success(message);
        } catch (error) {
            if (error?.response) {
                toast.error(error.response?.data?.message);
            } else {
                console.error("Error fetching data:", error);
            }
        } finally {
            setIsLoading(false)
        }
    };

    const toggleForm = () => setIsDisplayForm(!isDisplayForm)


    const actionMessages = {
        move: `<strong>${sender?.name || 'Unknown officer'}</strong> transferred this file, which was dispatched from the Daak Office on ${created_at || 'Unknown time'}.<br/> To proceed, you need to collect the file.`,
        approval: `<strong>${sender?.name || 'Unknown officer'}</strong> transferred this file for approval. It was dispatched from the Daak Office on ${created_at || 'Unknown time'}.<br/> To proceed, you need to collect the file.`,
        clarification: `<strong>${sender?.name || 'Unknown officer'}</strong> transferred this file requesting clarification. It was dispatched from the Daak Office on ${created_at || 'Unknown time'}.<br/> To proceed, you need to collect the file.`,
        escalation: `<strong>${sender?.name || 'Unknown officer'}</strong> escalated this file. It was dispatched from the Daak Office on ${created_at || 'Unknown time'}.<br/> To proceed, you need to collect the file.`,
    };

    const sentence = actionMessages[action.toLowerCase()] || '';


    return ((file?.last_tracking?.status === "dispatched") && (file?.last_tracking?.receiver.id === user.id)) ? (
        <Alert variant="info" className="mt-2">
            <div className="mb-3">
                <p dangerouslySetInnerHTML={{ __html: sentence }}></p>
            </div>

            {file?.last_tracking?.action === "approval" &&
                <div className='mb-3'>
                    <span className='text-bg-warning-soft text-dark rounded p-1'><strong>Note: </strong> This File was approved by {file?.last_tracking?.sender.name}, once you collect, it moves to <span className='badge text-bg-success-soft'>Completed</span> Status.</span>
                </div>
            }

            {isDisplayForm ?
                <div className='d-flex flex-column'>
                    <Form.Group className='mb-2'>
                        <Form.Label htmlFor="due_date">Comments (Optional)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            name="comments"
                            placeholder="leave your comments"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-center">
                        <Button
                            size="sm"
                            variant="info"
                            onClick={handleCollect}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Collecting...' : 'Collect'}
                        </Button>

                        <Button
                            size="sm"
                            variant="dark"
                            className='ms-2'
                            disabled={isLoading}
                            onClick={toggleForm}>Cancel
                        </Button>
                    </div>
                </div>
                :
                <div className="d-flex justify-content-center">
                    <Button
                        size="sm"
                        variant="info"
                        onClick={toggleForm}
                    >Collect File
                    </Button>
                </div>
            }
        </Alert>
    ) : null
}

export default CollectFile