import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  createFileApi,
  searchApproveUserApi,
  searchDaakUserApi,
  searchFileApi,
} from "../../services/FileService";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Block from "../../components/Block/Block";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../Constant/SelectInput";

function AddFilePage() {
  const [formData, setFormData] = useState({
    name: "",
    file_number:"",
    description:"",
    file_type_id: "",
    file_type: "",
    expected_complete_date: "",
    source: "",
    is_internal: true,
    is_new_file: true,
    priority_level: "",
    reopen_file_id: "",
    approve: false,
    due_date: "",
    approver_id: "",
  });
  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [fileOptionList, setFileOptionList] = useState([]);
  const [approveUserList, setApproveUserList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fileResponse = await searchFileApi();
        setFileOptionList(fileResponse.data.results);

        const approveResponse = await searchApproveUserApi();
        setApproveUserList(approveResponse.data.data);

      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (name, value, fullData) => {
    if (name === "file_type_id") {
      const isExistingItem = fileOptionList.some(
        (option) => option.value === value
      );

      setFormData((prev) => ({
        ...prev,
        file_type_id: isExistingItem ? value : null,
        file_type: isExistingItem ? "" : value,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleRadioChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "File Name is required";
    }
    if (!formData.file_number) {
      newErrors.file_number = "File Number is required";
    }
    if (!formData.priority_level) {
      newErrors.priority_level = "Priority is required";
    }  
    if (!formData.description) {
      newErrors.description = "Description is required";
    }
    if (!formData.file_type_id && !formData.file_type) {
      newErrors.file_type = "File Type is required";
    }
    if (!formData.expected_complete_date) {
      newErrors.expected_complete_date = "Expected Complete Date is required";
    }
    if (formData.approve && !formData.due_date) {
      newErrors.due_date = "Due Date is required";
    }if (formData.approve && !formData.approver_id) {
      newErrors.approver_id = "Approver is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) return;

    const payload = {
      ...formData,
      file_type_id: formData.file_type_id || "",
      file_type: formData.file_type || "",
    };

    try {
      setIsSubmitLoading(true);
      const response = await createFileApi(payload);
      setErrors({});
      toast.success(response.data.message);
      navigate("/files");
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <div className="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add File</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashbaord</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add File
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#" onSubmit={handleSubmit}>
          <Row className="g-gs">
            <Col xxl="12">
              <Card className="card-gutter-md">
                <Card.Body>
                  <Row className="g-gs mb-5">
                    {/* File Type: New or Reopen */}
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label as="legend" className="mb-3">
                          File Status
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            label="New File"
                            name="is_new_file"
                            id="new_file"
                            checked={formData.is_new_file}
                            onChange={() =>
                              handleRadioChange("is_new_file", true)
                            }
                            className="me-5"
                          />
                          <Form.Check
                            type="radio"
                            label="Reopen File"
                            name="is_new_file"
                            id="reopen_file"
                            checked={!formData.is_new_file}
                            onChange={() =>
                              handleRadioChange("is_new_file", false)
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    {/* File Source */}
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label as="legend" className="mb-3">
                          File Source
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            label="Internal"
                            name="is_internal"
                            id="internal_source"
                            checked={formData.is_internal}
                            onChange={() =>
                              handleRadioChange("is_internal", true)
                            }
                            className="me-5"
                          />
                          <Form.Check
                            type="radio"
                            label="External"
                            name="is_internal"
                            id="external_source"
                            checked={!formData.is_internal}
                            onChange={() =>
                              handleRadioChange("is_internal", false)
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    {!formData.is_new_file && (
                      <>
                        <Col lg="6">
                          <DynamicSelectInput
                            setParentInputValue={handleRadioChange}
                            endpoint_name="search_file"
                            name="reopen_file_id"
                            id="reopen_file_id"
                            placeholder="Select Reopen File"
                            label={"Reopen File"}
                          />
                        </Col>
                      </>
                    )}
                    {!formData.is_internal && (
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label htmlFor="source">
                            External File Source
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="source"
                            name="source"
                            placeholder="External File Source"
                            value={formData.source}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="name">File Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          name="name"
                          placeholder="File Name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                            <div className="text-danger fs-6">
                              {errors.name}
                            </div>
                          )}
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <StaticSelectInput
                          setParentInputValue={handleSelectChange}
                          optionsList={fileOptionList?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          name="file_type_id"
                          id="file_type_id"
                          value={formData.file_type_id || formData.file_type}
                          isClearable
                          placeholder="Choose or Create File Type"
                          label="File Type"
                          isRequired={true}
                        />
                        {(errors.file_type_id || errors.file_type) && (
                            <div className="text-danger fs-6">
                              {errors.file_type_id || errors.file_type}
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="file_number">File Number</Form.Label>
                        <Form.Control
                          type="text"
                          id="file_number"
                          name="file_number"
                          placeholder="File Number"
                          value={formData.file_number}
                          onChange={handleChange}
                        />
                         {errors.file_number && (
                            <div className="text-danger fs-6">
                              {errors.file_number}
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="name">Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          id="description"
                          rows={1}
                          name="description"
                          placeholder="Enter your description"
                          value={formData.description}
                          onChange={handleChange}
                        />
                        {errors.description && (
                            <div className="text-danger fs-6">
                              {errors.description}
                            </div>
                          )}
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="discount-price">
                          Expected Complete Date
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="date"
                            name="expected_complete_date"
                            value={formData.expected_complete_date}
                            onChange={handleChange}
                          />
                          {errors.expected_complete_date && (
                            <div className="text-danger fs-6">
                              {errors.expected_complete_date}
                            </div>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <StaticSelectInput
                          setParentInputValue={handleSelectChange}
                          optionsList={[
                            { label: "High", value: "high" },
                            { label: "Medium", value: "medium" },
                            { label: "Low", value: "low" },
                          ]}
                          name="priority_level"
                          id="priority_level"
                          isClearable
                          placeholder="Select Priority Level"
                          label="Priority Level"
                          isRequired={true}
                        />
                        {errors.priority_level && (
                            <div className="text-danger fs-6">
                              {errors.priority_level}
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <div className="d-flex mb-3">
                      <h3 className="me-5">Send File</h3>
                      {/* Approval */}
                      <Form.Group>
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="approve"
                            id="approve_yes"
                            checked={formData.approve}
                            onChange={() => handleRadioChange("approve", true)}
                            className="me-5"
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="approve"
                            id="approve_no"
                            checked={!formData.approve}
                            onChange={() => handleRadioChange("approve", false)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    {formData.approve && (
                      <>
                        <Col lg="6" className="mb-3">
                          <Form.Group>
                            <Form.Label htmlFor="due_date">Due Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="due_date"
                              value={formData.due_date}
                              onChange={handleChange}
                            />
                             {errors.due_date && (
                            <div className="text-danger fs-6">
                              {errors.due_date}
                            </div>
                          )}
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <StaticSelectInput
                            setParentInputValue={handleRadioChange}
                            optionsList={approveUserList?.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            name="approver_id"
                            id="approver_id"
                            placeholder="Select Approver"
                            label={"Approver"}
                          />
                          {errors.approver_id && (
                            <div className="text-danger fs-6">
                              {errors.approver_id}
                            </div>
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                </Card.Body>
                <Col xxl="12" className="d-flex gap-3 py-2 px-4">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? "Submitting..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => navigate("/files")}
                  >
                    Cancel
                  </Button>
                </Col>
              </Card>
            </Col>
          </Row>
        </Form>
      </Block>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(AddFilePage);
