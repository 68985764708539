import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Offcanvas, Alert } from 'react-bootstrap';
import { Icon } from '../../../components';
import SimpleBar from 'simplebar-react';

const Notification = () => {
    const [notificationList, setNotificationList] = useState([]);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [socket, setSocket] = useState(null);

    // Establish WebSocket connection
    useEffect(() => {
        const ws = new WebSocket(`${process.env.REACT_APP_WS_BASE_URL}/ws/file_notifications`);

        ws.onopen = () => {
            console.log('WebSocket connection established');
            ws.send(JSON.stringify({ type: 'subscribe', channel: 'notifications' })); // Optional: Custom handshake
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('Received notification:', data);
            setNotificationList((prevList) => [data, ...prevList]);
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
        };

        setSocket(ws);

        // Clean up on unmount
        return () => {
            ws.close();
        };
    }, []);

    // Offcanvas handlers
    const handleOffcanvasClose = () => setShowOffcanvas(false);
    const handleOffcanvasShow = () => setShowOffcanvas(true);

    return (
        <>
            <div className='me-2'>
                <button className="btn-icon btn btn-zoom btn-md position-relative" onClick={handleOffcanvasShow}>
                    <Icon size="lg" name="bell"></Icon>
                    {notificationList.length > 0 && (
                        <span
                            style={{ left: "80%", top: "7px", fontWeight: '300' }}
                            className="position-absolute translate-middle badge m-0 rounded-pill bg-danger light badge-sm"
                        >
                            {notificationList.length > 9 ? '9+' : notificationList.length}
                        </span>
                    )}
                </button>
            </div>
            <Offcanvas className="offcanvas-size-lg" placement="end" show={showOffcanvas} onHide={handleOffcanvasClose}>
                <Offcanvas.Header closeButton className="border-bottom border-light">
                    <Offcanvas.Title>Recent Notifications</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SimpleBar>
                        {notificationList.length === 0 ? (
                            <Alert variant="info">No notifications available.</Alert>
                        ) : (
                            <ul className="list-unstyled">
                                {notificationList.map((notification, index) => (
                                    <li key={index} className="mb-2">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>{notification.message}</span>
                                            <small className="text-muted">{notification.timestamp}</small>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </SimpleBar>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Notification;
