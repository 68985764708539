import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const FileDelayedTime = () => {
  // Sample data
  const data = {
    labels: ["MM", "Execution", "Finance", "ED Office"], // Department names
    datasets: [
      {
        label: "Average Clearance Time (Days)", // Dataset label
        data: [3, 4.5, 4, 4.2], // Average clearance time
        backgroundColor: "#f2bc16", // Bar color
        borderColor: "#f2bc16", // Bar border color
        borderWidth: 1, // Border width
        borderRadius: 8, // Rounded corners for bars
        barPercentage: 0.8, // Adjust bar width
      },
    ],
  };

  // Chart options for styling
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
          },
          color: "#333", // Legend text color
        },
      },
      tooltip: {
        backgroundColor: "#000",
        titleColor: "#fff",
        bodyColor: "#fff",
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines on x-axis
        },
        ticks: {
          color: "#333", // X-axis label color
          font: {
            size: 12,
          },
        },
      },
      y: {
        beginAtZero: true, // Start y-axis at zero
        grid: {
          color: "#e0e0e0", // Grid line color
        },
        ticks: {
          color: "#333", // Y-axis label color
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Average Delayed Time (Days)", // Y-axis title
          color: "#333",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
        <Card.Body>
    <div>
      <h3 style={{ fontFamily: "Arial, sans-serif", color: "#333" }}>
        File Delayed Time by Department
      </h3>
      <Bar data={data} options={options} />
    </div>
    </Card.Body>
    </Card>
  );
};

export default FileDelayedTime;
