import { useLayoutEffect, useRef } from 'react'

import pureknob from '../../assets/js/pureknob/pureknob.js';
import { Colors } from '../../utilities/index';

function Pureknob({data, className, ...props}) {
    let knobRef = useRef(null);
    const knobInstance = useRef(null);

    useLayoutEffect(() => {
      if (knobRef.current) {
        const {
          size = 100,
          angleStart = 1,
          angleEnd = 1,
          angleOffset = 0,
          colorBg = Colors.gray200,
          colorFg = Colors.primary,
          trackWidth = 0.2,
          min = 0,
          max = 100,
          readonly = true,
          value = 0,
          hideValue = false,
        } = data;
  
        // Create knob element with the given size.
        const knob = pureknob.createKnob(size, size);
  
        // Set properties.
        knob.setProperty('angleStart', -angleStart * Math.PI);
        knob.setProperty('angleEnd', angleEnd * Math.PI);
        knob.setProperty('angleOffset', angleOffset * Math.PI);
        knob.setProperty('colorFG', colorFg);
        knob.setProperty('colorBG', colorBg);
        knob.setProperty('trackWidth', trackWidth);
        knob.setProperty('valMin', min);
        knob.setProperty('valMax', max);
        knob.setProperty('readonly', readonly);
        knob.setProperty('textScale', hideValue ? 0 : 1); // Hide value if `hideValue` is true.
  
        // Set the initial value.
        knob.setValue(value);
  
        // Create element node.
        const node = knob.node();
  
        // Append the knob node to the DOM.
        knobRef.current.appendChild(node);
  
        // Store the knob instance for cleanup.
        knobInstance.current = knob;

            return () => {
              knobRef.current = null;
            }
        }
    })

  return (
    <div ref={knobRef} className={className}>{props.children}</div>
  )
}

export default Pureknob;


// import { useLayoutEffect, useRef } from 'react';
// import pureknob from '../../assets/js/pureknob/pureknob.js';
// import { Colors } from '../../utilities/index';

// function Pureknob({ data, className, ...props }) {
//   const knobRef = useRef(null);
//   const knobInstance = useRef(null); // Keep track of the knob instance.

//   useLayoutEffect(() => {
//     if (knobRef.current) {
//       const {
//         size = 100,
//         angleStart = 1,
//         angleEnd = 1,
//         angleOffset = 0,
//         colorBg = Colors.gray200,
//         colorFg = Colors.primary,
//         trackWidth = 0.2,
//         min = 0,
//         max = 100,
//         readonly = true,
//         value = 0,
//         hideValue = false,
//       } = data;

//       // Create knob element with the given size.
//       const knob = pureknob.createKnob(size, size);

//       // Set properties.
//       knob.setProperty('angleStart', -angleStart * Math.PI);
//       knob.setProperty('angleEnd', angleEnd * Math.PI);
//       knob.setProperty('angleOffset', angleOffset * Math.PI);
//       knob.setProperty('colorFG', colorFg);
//       knob.setProperty('colorBG', colorBg);
//       knob.setProperty('trackWidth', trackWidth);
//       knob.setProperty('valMin', min);
//       knob.setProperty('valMax', max);
//       knob.setProperty('readonly', readonly);
//       knob.setProperty('textScale', hideValue ? 0 : 1); // Hide value if `hideValue` is true.

//       // Set the initial value.
//       knob.setValue(value);

//       // Create element node.
//       const node = knob.node();

//       // Append the knob node to the DOM.
//       knobRef.current.appendChild(node);

//       // Store the knob instance for cleanup.
//       knobInstance.current = knob;

//       return () => {
//         // Cleanup: Remove the knob element.
//         if (knobRef.current) {
//           knobRef.current.innerHTML = '';
//         }
//         knobInstance.current = null;
//       };
//     }
//   }, [data]); // Re-run effect if `data` changes.

//   return (
//     <div ref={knobRef} className={className}>
//       {props.children}
//     </div>
//   );
// }

// export default Pureknob;
