import React, { useState, useEffect, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const FileStatusDistribution = () => {
    const [chartData, setChartData] = useState(null);

    // Sample data for the chart
    const sampleData = {
        approval: 50,
        pending: 30,
        overdue: 20,
    };

    const fetchData = useCallback(() => {
        // Simulate fetching data and setting up the chart
        const data = {
            labels: ['Approval', 'Pending', 'Overdue'],
            datasets: [
                {
                    data: [sampleData.approval, sampleData.pending, sampleData.overdue],
                    backgroundColor: ['#478ffc', '#2dc58c', '#8927f9'],
                    hoverBackgroundColor: ['#478ffc', '#2dc58c', '#8927f9'],
                    borderWidth: 1,
                    hoverOffset: 8, // Provides a hover 3D effect
                },
            ],
        };
        setChartData(data);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Card className="h-100 shadow-sm">
            <Card.Body>
                <div className="card-title-group">
                    <h5 className="title text-center mb-4">File Status Distribution</h5>
                </div>

                {chartData ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <Pie
                            data={chartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                        labels: {
                                            boxWidth: 20,
                                            padding: 15,
                                            font: {
                                                size: 14,
                                                family: 'Arial',
                                                weight: 'bold',
                                            },
                                            color: '#333',
                                        },
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: function (tooltipItem) {
                                                const value = tooltipItem.raw;
                                                return ` ${tooltipItem.label}: ${value} %`;
                                            },
                                        },
                                    },
                                    datalabels: {
                                        display: true,
                                        color: '#fff',
                                        font: {
                                            size: 16,
                                            weight: 'bold',
                                        },
                                        formatter: (value, context) => {
                                            const dataset = context.chart.data.datasets[0];
                                            const total = dataset.data.reduce((sum, val) => sum + val, 0);
                                            const percentage = ((value / total) * 100).toFixed(1);
                                            return `${percentage}%`;
                                        },
                                    },
                                },
                                animation: {
                                    animateScale: true,
                                    animateRotate: true,
                                },
                            }}
                        />
                    </div>
                ) : (
                    <div className="text-center">Loading Chart...</div>
                )}
            </Card.Body>
        </Card>
    );
};

export default FileStatusDistribution;
