import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { formatUnderscoreString } from "../../utilities";
import SimpleBar from "simplebar-react";
import { Dropdown, Button, Offcanvas, Alert, Card } from "react-bootstrap";
import {
  Image,
  Icon,
  MediaGroup,
  MediaText,
  Media,
  Schedule,
} from "../../components";
import { getFileLogApi } from "../../services/FileService";
import { Spinner } from "react-bootstrap";

function FileLog() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [fileLog, setFileLog] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState("");
  const recordsPage = 25;

  const fetchFileLog = useCallback(() => {
    setIsLoading(true);
    getFileLogApi(id)
      .then((resp) => {
        setFileLog(resp.data.results);
      })
      .catch((error) => {
        console.error(
          "Error fetching file log:",
          error.response?.data?.message || error.message
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    fetchFileLog();
  }, [fetchFileLog]);

  return (
    <div className="p-3">
      <SimpleBar style={{ height: '600px' }}>
        <Schedule>
          {isLoading ? (
            <div className="text-center my-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : fileLog.length > 0 ? (
            fileLog.map((file, index) => (
              <Schedule.Item
                symbol="active"
                contentClass="nk-schedule-content-no-border flex-grow-1"
                key={index}
              >
                <RenderTrackingItem file={file} />
              </Schedule.Item>
            ))
          ) : <h3>Tracking  info not available </h3>}
        </Schedule>
      </SimpleBar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});
export default connect(mapStateToProps)(FileLog);

const RenderTrackingItem = ({ file }) => {
  const status = file.status;
  const statusComponentMap = {
    out: <OutLogRecord file={file} />,
    received: <ReceiveLogRecord file={file} />,
    dispatched: <DispatchLogRecord file={file} />,
    in: <InLogRecord file={file} />,
  };

  return statusComponentMap[status] || null;
};

const ActionStatus = ({ status }) => {
  const statusBadgeMap = {
    move: 'danger-soft',
    escalation: 'warning-soft',
    approval: 'success-soft',
    clarification: 'info-soft',
  };

  const badgeClass = statusBadgeMap[status];
  return badgeClass ? <span className={`badge text-bg-${badgeClass}`}>{formatUnderscoreString(status)}</span> : null;
};

const ConvertToReadableTime = ({hours}) => {
  const days = Math.floor(hours / 24); // Calculate full days
  const remainingHours = Math.floor(hours % 24); // Remaining hours after extracting full days
  const minutes = Math.round((hours % 1) * 60); // Convert the fractional part into minutes
  let result = '';
  if (days > 0) {
    result += `${days} Day${days > 1 ? 's' : ''} `;
  }
  if (remainingHours > 0) {
    result += `${remainingHours}:${minutes} Hr${remainingHours > 1 ? 's' : ''} `;
  }
  return <div>
    {result.trim() || ''}
  </div>;
}


const LogRecord = ({ file, status, variant, badgeText }) => {
  return (
    <Alert variant={variant} className="mt-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <span className="smaller me-2">
            <Icon name="clock" className="me-1" /> {file?.created_at}
          </span>
          <ActionStatus status={file.action} />
        </div>
        <span className="smaller me-2 text-muted">
          <Icon name="calender-date-fill" className="me-1" />
          <span className="me-1">Due Date: <strong>{file?.due_date}</strong></span>
          <span className={`badge text-bg-${variant} ms-2`}>{badgeText}</span>
        </span>
      </div>

      <div className="d-flex justify-content-around">
        <div className="d-flex flex-wrap flex-grow-1 justify-content-between">
          {[...(['received', 'dispatched'].includes(file.status) ? ['daak_user'] : []),
            'sender', 'receiver'].map((role) => (
              <div className="me-3" key={role}>
                <h6 className="mb-1 d-flex align-items-center">{formatUnderscoreString(role)}</h6>

                {['name', 'phone_number', 'department'].map((field) => (
                  <p className="text-muted smaller mb-1" key={field}>
                    <Icon name={field === 'phone_number' ? 'call-fill' : field === 'department' ? 'building' : 'user-fill'} className="me-2 text-secondary" />
                    {file[role]?.[field]}
                  </p>
                ))}

              </div>
            ))}

          <div>
            {file.time_taken > 0 && <ConvertToReadableTime hours={file.time_taken} />}
          </div>
        </div>
      </div>

      {file.comments && (
        <div className="d-flex align-items-center mt-2">
          <Icon name="comments" /> <span className="fs-6 ms-2">{file.comments}</span>
        </div>
      )}
    </Alert>
  );
};

const OutLogRecord = ({ file }) => {
  return <LogRecord file={file} status="out" variant="danger" badgeText="OUT" />;
};

const ReceiveLogRecord = ({ file }) => {
  return <LogRecord file={file} status="received" variant="info" badgeText="Received" />;
};

const DispatchLogRecord = ({ file }) => {
  return <LogRecord file={file} status="dispatched" variant="success" badgeText="Dispatched" />;
};

const InLogRecord = ({ file }) => {
  return <LogRecord file={file} status="in" variant="warning" badgeText="IN" />;
};
