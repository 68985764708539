import React from 'react'
import Nav from 'react-bootstrap/nav'

function Footer() {
    return (
        <div className="nk-footer">
            <div className="container-fluid">
                <div className="nk-footer-wrap">
                    <div className="nk-footer-copyright">
                        <p>&copy; {new Date().getFullYear()} InteliTrack. Powered by <a href="https://intelisparkz.com" target="_blank" rel="noreferrer" className="text-primary">InteliSparkz</a>. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer