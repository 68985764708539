import React, { useState, useCallback, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { Icon, Pureknob } from "../../../components";
import { Colors } from "../../../utilities/index";
import { getReceiveDispatchCount } from "../../../services/DashboardService";

const ReceiveFileCount = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getReceiveDispatchCount()
      .then((resp) => {
        const receivedFiles = Number(resp.data.to_receive) || 0;
        setData(receivedFiles);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const activeProject = {
    size: 136,
    value: data,
    angleOffset: -0.5,
    angleStart: 0.7,
    angleEnd: 0.7,
    colorFg: Colors.white,
    trackWidth: "0.15",
  };

  return (
    <Card className="h-100 bg-info">
      <Card.Body>
        <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Pureknob
                data={activeProject}
                className="nk-chart-project-done text-white"
              >
                {" "}
                <span className="knob-title small text-white">Received</span>
              </Pureknob>
              <div className="card-title mb-0 mt-4 mt-sm-0">
                <h5 className="title mb-3 mb-xl-5 text-white">Receive Files</h5>
                <div className="amount h2 text-white">
                  <Icon name="file-fill"></Icon> {data} files Received
                </div>
              </div>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ReceiveFileCount;
