import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EscalationTrendsChart = () => {
  const data = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Number of Escalations",
        data: [6, 12, 15, 12],
        borderColor: "red",
        backgroundColor: "red",
        tension: 0.1,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Escalation Trends",
        font: { size: 18 },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Weeks",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Escalations",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
    <Card.Body>
    <div>
      <h3 style={{ fontFamily: "Arial, sans-serif", color: "#333" }}>
      Escalation Trends
      </h3>
  <Line data={data} options={options} />
  </div>
  </Card.Body>
  </Card> 
);
};

export default EscalationTrendsChart;
