import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { recieveFileApi, sendFileApi } from "../../../../../services/FileService";
import { Badge, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import ReceiveFileForm from "./ReceiveFileForm";
import StatusINFilesReceive from "./StatusINFilesReceive";
import { Icon } from "../../../../../components";

function ReceiveFile() {
  const [formData, setFormData] = useState({
    barcode_number: "",
  });
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1); // Step control
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
    setCurrentStep(1); // Reset to the first step on modal close
    setFormData({ barcode_number: "" });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear errors for the field
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleVerifyFile = async (e) => {
    e.preventDefault();

    if (!formData.barcode_number.trim()) {
      setErrors({ barcode_number: "Barcode number is required" });
      return;
    }

    try {
      setIsSubmitLoading(true);
      const response = await recieveFileApi(formData.barcode_number);
      setData(response.data.results);
      setErrors({});
      toast.success(response.data?.message);
      setCurrentStep(2);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      setErrors({ api: errorMessage });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Badge bg="info" onClick={toggleModal} style={{ cursor: "pointer" }}>
        Receive File <Icon name="curve-right-down" />
      </Badge>
      {isModalVisible && <div className="fade modal-backdrop show"></div>}
      <Modal
        show={isModalVisible}
        onHide={toggleModal}
        size={`${(currentStep === 2 && data?.status !== "out" ) ? "lg" : "md" }`}
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {currentStep === 1 ? "Receive File" : "Dispatch File"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentStep === 1 && (
            <Form onSubmit={handleVerifyFile}>
              <Row className="g-gs mb-4">
                <Col lg="12">
                  <Form.Group>
                    <Form.Label htmlFor="barcode_number">
                      Enter Barcode Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="barcode_number"
                      id="barcode_number"
                      value={formData.barcode_number}
                      onChange={handleChange}
                      isInvalid={!!errors.barcode_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.barcode_number}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex gap-3">
                <Button
                  type="submit"
                  variant="success"
                  size="sm"
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? "Verifying..." : "Verify"}
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}

          {currentStep === 2 && (
            <div>
              {data?.status === "out" ? (
                <ReceiveFileForm
                  data={data}
                  barcodeNumber={formData.barcode_number}
                  onResetStep={() => {
                    setCurrentStep(1);
                    setFormData({ barcode_number: "" });
                    setErrors({});
                  }}
                  toggleModal={toggleModal}
                />
              ) : (
                <StatusINFilesReceive barcodeNumber={formData.barcode_number} />
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(ReceiveFile);
