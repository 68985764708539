import React, { useState, useEffect } from "react";
import { Card, Dropdown, Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Block from '../../components/Block/Block';
import { Select, Icon } from '../../components';
import { createUserApi, searchUserApi } from "../../services/UserService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { deptOptions, roleOptions } from "../utilities/Constant";

const UserAddPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        phone_number: "",
        role: null,
        designation: "",
        personnel_number: "",
        department: null,
        reports_to: null,
        file_permission: { create: false, approve: false, delete: false },
    });

    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});

    const [reportsToOptions, setReportsToOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await searchUserApi();
                const { data } = response.data;
                setReportsToOptions(data);
                setLoading(false)

            } catch (error) {
                console.error("Error fetching data", error);
                setLoading(false)
            }
        };

        fetchData();
    }, []);

    // Handle change in input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle select change for dropdowns
    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    // Toggle password visibility
    const handlePasswordToggle = () => {
        setPasswordVisible(!passwordVisible);
    };

    // Handle permission changes
    const handlePermissionChange = (field, action, checked) => {
        setFormData((prev) => ({
            ...prev,
            [field]: { ...prev[field], [action]: checked },
        }));
    };

    const handleSelectAllPermissionChange = (field, checked) => {
        setFormData((prev) => ({
            ...prev,
            [field]: {
                create: checked,
                approve: checked,
                delete: checked,
            },
        }));
    };

    // Validate form fields
    const validateForm = () => {
        const newErrors = {};
        const passwordRegex =
            /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/;
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.password) {
            newErrors.password = "Password is required";
        }
        // if (!passwordRegex.test(formData.password)) {
        //     newErrors.password =
        //         "Password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a special character";
        // }

        if (!formData.phone_number) newErrors.phone_number = "Phone Number is required";
        if (!formData.role) newErrors.role = "Role is required";
        if (!formData.department) newErrors.department = "Department is required";
        if (!formData.designation) newErrors.designation = "Designation is required";
        if (!formData.personnel_number) newErrors.personnel_number = "Personnal Number is required";
        if (!formData.reports_to) newErrors.reports_to = "Reports To is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                setIsSubmitLoading(true);
                const response = await createUserApi(formData);
                setErrors({});
                toast.success(response?.data?.message);
                navigate("/users");
            } catch (error) {
                toast.error(error.response?.data?.message || "An error occurred");
            } finally {
                setIsSubmitLoading(false);
            }
        }
    };


    // Handle cancel
    const handleCancel = () => {
        setFormData({
            name: "",
            email: "",
            password: "",
            phone_number: "",
            role: null,
            designation: "",
            personnel_number: "",
            department: null,
            reports_to: null,
            file_permission: { create: false, approve: false, delete: false },
        });
        setErrors({});
        navigate("/users");
    };


    return (
        <div className="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Add New User</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="/users">User Management</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add User</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>

                </Block.HeadBetween>
            </Block.Head>


            <Block>
                <Form action="#" onSubmit={handleSubmit}>
                    <Row className="g-gs">
                        <Col xxl="12">
                            <div className="gap gy-4">
                                <div className="gap-col">
                                    <Card className="card-gutter-md">
                                        <Card.Body>
                                            <h4 className="bio-block-title mb-4">Add Profile</h4>
                                            <Row className="g-gs">

                                                {/*  Name */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Name <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            placeholder="Enter  Name"
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.name && <div className="text-danger">{errors.name}</div>}
                                                    </Form.Group>
                                                </Col>


                                                {/* Email */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Email <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            placeholder="Enter Email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.email && <div className="text-danger">{errors.email}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Password */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Password <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <div style={{ position: "relative" }}>
                                                            <Form.Control
                                                                type={passwordVisible ? "text" : "password"}
                                                                name="password"
                                                                placeholder="Enter Password"
                                                                value={formData.password}
                                                                onChange={handleChange}
                                                                autoComplete="new-password"
                                                                style={{ paddingRight: "2.5rem" }}
                                                            />
                                                            <span
                                                                onClick={handlePasswordToggle}
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "50%",
                                                                    transform: "translateY(-50%)",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {passwordVisible ? <Icon name="eye-alt-fill"></Icon> : <Icon name="eye-off-fill"></Icon>}

                                                            </span>
                                                        </div>
                                                        {errors.password && <div className="text-danger">{errors.password}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Phone Number */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Phone Number <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone_number"
                                                            placeholder="Enter Phone Number"
                                                            value={formData.phone_number}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.phone_number && <div className="text-danger">{errors.phone_number}</div>}
                                                    </Form.Group>
                                                </Col>


                                                {/* Role Select */}
                                                <Col lg="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label> Role <span className="text-danger">*</span></Form.Label>

                                                        <Select
                                                            id="role"
                                                            name="role"
                                                            value={formData.role}
                                                            onChange={handleSelectChange}
                                                        >
                                                            <option value="">
                                                                Choose Role
                                                            </option>
                                                            {roleOptions?.map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Select>

                                                        {errors.role && <div className="text-danger">{errors.role}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Designation */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Designation <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="designation"
                                                            placeholder="Enter Designation"
                                                            value={formData.designation}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.designation && <div className="text-danger">{errors.designation}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Personnel Number */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Personnel Number <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="personnel_number"
                                                            placeholder="Enter Personnel Number"
                                                            value={formData.personnel_number}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.personnel_number && <div className="text-danger">{errors.personnel_number}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Department Select */}
                                                <Col lg="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>
                                                            Department <span className="text-danger">*</span>
                                                        </Form.Label>

                                                        <Select
                                                            id="department"
                                                            name="department"
                                                            value={formData.department}
                                                            onChange={handleSelectChange}
                                                        >
                                                            <option value="">
                                                                Choose Department
                                                            </option>
                                                            {deptOptions?.map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Select>

                                                        {errors.department && <div className="text-danger">{errors.department}</div>}
                                                    </Form.Group>
                                                </Col>



                                                {/* Reports To */}
                                                <Col lg="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>
                                                            Reports To <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        {loading ? (
                                                            <div>Loading...</div>
                                                        ) : (

                                                            <Select
                                                                id="reports_to"
                                                                name="reports_to"
                                                                value={formData.reports_to}
                                                                onChange={handleSelectChange}
                                                            >
                                                                <option value="">
                                                                    Choose Reports To
                                                                </option>

                                                                {reportsToOptions?.map((option) => (
                                                                    <option
                                                                        key={option.value}
                                                                        value={option.value}
                                                                    >
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </Select>

                                                        )}
                                                        {errors.reports_to && <div className="text-danger">{errors.reports_to}</div>}
                                                    </Form.Group>
                                                </Col>



                                                {/* Permissions Table */}

                                                {(formData.role !== "daak" && formData.role !== "admin") && (
                                                    <Form.Group>
                                                        <Form.Label>Permissions</Form.Label>
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Permission</th>
                                                                    <th>Create</th>
                                                                    <th>Approve</th>
                                                                    <th>Delete</th>
                                                                    <th>Select All</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>File Permission</td>
                                                                    {["create", "approve", "delete"].map((action) => (
                                                                        <td key={action}>
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    value={action}
                                                                                    checked={formData.file_permission[action]}
                                                                                    onChange={(e) => handlePermissionChange("file_permission", action, e.target.checked)}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    ))}
                                                                    <td>
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={Object.values(formData["file_permission"]).every((val) => val)}
                                                                                onChange={(e) => handleSelectAllPermissionChange("file_permission", e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Form.Group>
                                                )}

                                                {/* Submit and Cancel Buttons */}
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Button variant="primary" onClick={handleSubmit} disabled={isSubmitLoading}>
                                                        {isSubmitLoading ? "Submitting..." : "Submit"}
                                                    </Button>
                                                    <Button variant="secondary" onClick={handleCancel} className="ms-3">
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Block>
        </div>
    )


};

export default UserAddPage;