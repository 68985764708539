import { connect } from "react-redux";
import FileStatusDistribution from "./Management/FileStatusDistribution";
import AverageFileClearanceTime from "./Management/AverageFileClearanceTime";
import FileDelayedTime from "./Management/FileDelayedTime";
import EscalationTrendsChart from "./Management/EscalationTrends";
import KPIData from "./Management/KPIData";
import {
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  Table,
  Badge,
  ProgressBar,
} from "react-bootstrap";
import {
  Pureknob,
  Icon,
  Media,
  MediaGroup,
  MediaText,
  CustomDropdownMenu,
  CustomDropdownToggle,
  OverlineTitle,
  Schedule,
  Image,
} from "../../components";
import ReceiveFileCount from "./DaakUser/ReceiveFileCount";
import DispatchFileCount from "./DaakUser/DispatchFileCount";
import ReceiveFileList from "./DaakUser/ReceiveFileList";
import DispatchFileList from "./DaakUser/DispatchFileList";

function Home({ userRole }) {

  return (
    <div className="container">
      {userRole === "management" ? (
        <Row className="g-gs">
          <Col xxl="4">
            <FileStatusDistribution />
          </Col>

          <Col xxl="6">
            <AverageFileClearanceTime />
          </Col>
          <Col xxl="2">
            <KPIData />
          </Col>
          <Col xxl="6">
            <FileDelayedTime />
          </Col>
          <Col xxl="6">
            <EscalationTrendsChart />
          </Col>
        </Row>
      ) : userRole === "daak" ? (
        <Row className="g-gs">
          <Col xxl="6">
           <ReceiveFileCount />
          </Col>
          <Col xxl="6">
            <DispatchFileCount />
          </Col>

          <Col xxl="6">
           <ReceiveFileList />
          </Col>
          <Col xxl="6">
            <DispatchFileList />
          </Col>
        </Row>
      ) : (
        <div>Admin Dashboard</div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});
export default connect(mapStateToProps)(Home);
