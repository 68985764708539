

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tab, Nav, Card, Button, Alert, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import Block from '../../components/Block/Block';
import { Image, Icon, Schedule, Media, MediaGroup, MediaText } from '../../components';
import { toInitials } from "../../utilities";
import { getUserApi } from "../../services/UserService";
import { deptOptions, roleOptions } from "../utilities/Constant";
import Loading from "../../components/Loader/Loading";

function UserProfilePage() {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchUser = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await getUserApi(id);
                setUser(response?.data?.results);
            } catch (err) {
                setError(err.message || "An error occurred while fetching user data.");
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [id]);


    if (loading) return <div><Loading /></div>;
    if (error) return <div>Error: {error}</div>;
    return (
        <div className="container">
            <Block.Head>
                <Block.HeadBetween className="align-items-start">
                    <Block.HeadContent>
                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                            <Media size="huge" shape="circle" variant="dark-soft">
                                {user?.profile_photo ? (
                                    <Image
                                        src={`${process.env.REACT_APP_BASE_URL}${user?.profile_photo}`}
                                        staticImage
                                        thumbnail
                                        alt="user"
                                    />
                                ) : (
                                    <span className="fw-medium">{toInitials(user?.name)}</span>
                                )}
                            </Media>

                            <div className="mt-3 mt-md-0 ms-md-3">
                                <h3 className="title mb-1">{user?.name}</h3>
                                <h5 className="small">{user?.designation}</h5>
                                <ul className="nk-list-option pt--3">
                                    <li>
                                        <Icon name="map-pin" />:
                                        &nbsp;
                                        <span className="small">
                                            {user?.address || "Springfield, IL6 United States"}
                                        </span>
                                    </li>
                                    <li>
                                        <Icon name="building" />:
                                        &nbsp;
                                        <span className="small">
                                            {deptOptions.find((option) => option.value === user?.department)?.label || "NA"}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <div className="d-flex gap g-3">
                            <div className="gap-col">
                                <ul className="d-flex gap g-2">
                                    <li className="d-none d-md-block">
                                        <Link to={`/edit-user/${user?.id}`}
                                            className="btn btn-soft btn-primary">
                                            <Icon name="edit"></Icon>
                                            <span>Edit Profile</span>
                                        </Link>

                                    </li>
                                    <li className="d-md-none">
                                        <Link to={`/edit-user/${user?.id}`} className="btn btn-soft btn-primary btn-icon">
                                            <Icon name="edit"></Icon>
                                        </Link>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Tab.Container defaultActiveKey="tabOne">
                <Block className="mt-2">
                    <Tab.Content>
                        <Tab.Pane eventKey="tabOne">
                            <Card className="card-gutter-md">
                                <div className="card-row card-row-lg col-sep col-sep-lg">
                                    <div className="card-aside">
                                        <Card.Body>
                                            <div className="bio-block">
                                                <div className="d-flex align-items-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary btn-sm btn-circle"
                                                        onClick={() => navigate("/users")}
                                                    >
                                                        <Icon name="arrow-left"></Icon>
                                                    </button>
                                                    <h4 className="bio-block-title mx-3">Details</h4>
                                                </div>

                                                <ul className="list-group list-group-borderless small">
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Personnel Number:</span>
                                                        <span className="text">{user?.personnel_number}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Full Name:</span>
                                                        <span className="text">{user?.name}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Email:</span>
                                                        <span className="text">{user?.email}</span>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Phone No.:</span>
                                                        <span className="text">{user?.phone_number}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Role:</span>
                                                        <span className={`badge small text-bg-dark-soft`}>
                                                            {roleOptions.find((option) => option.value === user?.role)?.label || "NA"}
                                                        </span>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Date Of Joining:</span>
                                                        <span className="text">{user?.created_at || "NA"}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Status:</span>
                                                        <span className={`badge text-bg-${user?.status === "active" ? "success-soft"
                                                            : user?.status === "blocked" ? "danger-soft"
                                                                : user?.status === "inactive" ? "secondary-soft"
                                                                    : "primary-soft"}`}
                                                        >
                                                            {user?.status
                                                                ? user?.status.charAt(0).toUpperCase() + user?.status.slice(1).toLowerCase()
                                                                : 'General'}
                                                        </span>

                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Reports To:</span>
                                                        <span className="text">
                                                            <Link to={`/user/${user?.reports_to?.id}`} className="title">
                                                                <strong>{user?.reports_to?.name}</strong>

                                                            </Link>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                            <div className="bio-block">
                                                <h4 className="bio-block-title mb-2">File Permission</h4>
                                                {user?.file_permission && Object.keys(user.file_permission).length > 0 ? (
                                                    <ul className="d-flex flex-wrap gap gx-1" style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                                        {Object.entries(user.file_permission).map(([permission, isEnabled]) => (
                                                            <li
                                                                key={permission}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: "0.25rem 0.4rem",
                                                                    margin: "0.25rem",
                                                                    fontSize: "0.75rem",
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isEnabled}
                                                                    disabled
                                                                    style={{
                                                                        cursor: "not-allowed",
                                                                        marginRight: "0.2rem",
                                                                    }}
                                                                />
                                                                <label style={{ margin: 0, fontSize: "0.85rem", color: "#495057" }}>
                                                                    {permission.charAt(0).toUpperCase() + permission.slice(1)}
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <p style={{ fontSize: "0.85rem", color: "#6c757d" }}>File permission not granted</p>
                                                )}
                                            </div>


                                        </Card.Body>
                                    </div>
                                    <div className="card-content col-sep">
                                        <Card.Body >
                                            <div className="bio-block" >
                                                <h4 className="bio-block-title">About Me</h4>
                                                <p>
                                                    {user?.description
                                                        ? parse(user?.description)
                                                        : "I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore? "}
                                                </p>

                                                <Row className="g-gs pt-2">
                                                    <Col lg="6">
                                                        <span style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                            <span className="title fw-medium w-20 d-inline-block">Designation:</span>
                                                            <span className="text">{user?.designation}</span>
                                                        </span>
                                                    </Col>
                                                    <Col lg="6">
                                                        <span style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                            <span className="title fw-medium w-20 d-inline-block">Last Login:</span>
                                                            <span className="text">{user?.last_login}</span>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card.Body>
                                        <Card.Body>
                                            <div className="bio-block">
                                                <h4 className="bio-block-title">Recent Activity</h4>
                                                {/* {user?.activity && 
                                                <Schedule className="mt-4">
                                                    {user?.activity.map((item, index) => {
                                                        return (
                                                            <Schedule.Item symbol="active" key={index} contentClass={`${
                                                                        item.type === "file" ? 'nk-schedule-content-no-border' : 
                                                                        item.type === 'alert' ? 'nk-schedule-content-no-border flex-grow-1' : ''
                                                                    }`}
                                                                >
                                                                <span className="smaller">{item.time}</span>
                                                                <div className="h6">{item.title}</div>
                                                                {item.images && 
                                                                    <ul className="d-flex flex-wrap gap g-2 pt-2">
                                                                        {item.images.map((el, index) => (
                                                                            <li key={index}>
                                                                                <Media size="xxl">
                                                                                    <Image src={el.src} alt={el.alt} thumbnail />
                                                                                </Media>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                }
                                                                {item.files && 
                                                                    <div className="list-group-dotted mt-3">
                                                                        <div className="list-group-wrap">
                                                                            {item.files.map((el, index) => (
                                                                                <div className="p-3" key={index}>
                                                                                    <MediaGroup>
                                                                                        <Media className="rounded-0">
                                                                                            <Image src={`${
                                                                                                el.type === 'pdf' ? '/images/icon/file-type-pdf.svg' : 
                                                                                                el.type === 'doc' ? '/images/icon/file-type-doc.svg' : 
                                                                                                el.type === 'code' ? '/images/icon/file-type-code.svg' : 
                                                                                                '/images/icon/file-type-pdf.svg'}`} alt="icon"
                                                                                            />
                                                                                        </Media>
                                                                                        <MediaText className="ms-1">
                                                                                            <a href="#download" className="title">{el.title}</a>
                                                                                            <span className="text smaller">{el.size}</span>
                                                                                        </MediaText>
                                                                                    </MediaGroup>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {item.alerts &&
                                                                    item.alerts.map((el, index) => {
                                                                        return (
                                                                            <Alert variant="info" className="mt-2" key={index}>
                                                                                <div className="d-flex">
                                                                                    <Icon className="opacity-75" size="lg" name={el.icon}></Icon>
                                                                                    <div className="ms-2 d-flex flex-wrap flex-grow-1 justify-content-between">
                                                                                        <div>
                                                                                            <h6 className="alert-heading mb-0">{el.title}</h6>
                                                                                            <span className="smaller">{el.description}</span>
                                                                                        </div>
                                                                                        <ul className="d-flex gap g-2 pt-1">
                                                                                            {el.buttons && el.buttons.map((btn, index) => {
                                                                                                return (
                                                                                                    <li key={index}>
                                                                                                        <Button size="md" variant="info">
                                                                                                            <Icon name={btn.icon}></Icon>
                                                                                                            <span>{btn.text}</span>
                                                                                                        </Button>
                                                                                                    </li>
                                                                                                )
                                                                                            })}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </Alert>
                                                                        )
                                                                    })
                                                                }
                                                            </Schedule.Item>
                                                        )
                                                    })}
                                                </Schedule>
                                            } */}
                                            </div>
                                        </Card.Body>
                                    </div>
                                </div>
                            </Card>
                        </Tab.Pane>
                    </Tab.Content>
                </Block>
            </Tab.Container>

        </div>
    )
}

export default UserProfilePage;