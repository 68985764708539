import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { approveFileApi, searchApproveUserApi } from "../../../services/FileService";
import { Badge, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { StaticSelectInput } from "../../Constant/SelectInput";
import { Icon } from "../../../components";

function ApproveFileModal({ data }) {
  const [formData, setFormData] = useState({
    due_date: "",
    comments: "",
    approver_id: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [approveUserList, setApproveUserList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!isModalVisible) return;

      try {
        const approveResponse = await searchApproveUserApi();
        setApproveUserList(approveResponse.data?.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data.");
      }
    };

    fetchData();
  }, [isModalVisible]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRadioChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      id: data?.id || "",
    };

    try {
      setIsSubmitLoading(true);
      const response = await approveFileApi(payload);
      setErrors({});
      toast.success(response.data?.message);
      setIsModalVisible(false);
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Badge
        bg="success"
        onClick={() => setIsModalVisible(true)}
        style={{ cursor: "pointer" }}
      >
        Approval File <Icon name="arrow-up-right" />
      </Badge>
      <Modal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Approve File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="g-gs mb-4">
              <Col lg="12">
                <StaticSelectInput
                  setParentInputValue={handleRadioChange}
                  optionsList={approveUserList?.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  name="approver_id"
                  id="approver_id"
                  isRequired={true}
                  placeholder="Select Next officer"
                  label={"Next Officer"}
                />
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label htmlFor="due_date">Due Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="due_date"
                    value={formData.due_date}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label htmlFor="due_date">
                    Comments (Optional)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="comments"
                    placeholder="leave your comments"
                    value={formData.comments}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex gap-3">
              <Button
                type="submit"
                variant="success"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? "Approving..." : "Approve"}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate("/files")}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(ApproveFileModal);
