
import React, { useState, useEffect, useCallback } from "react";
import { CancelRequest } from "../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { Badge, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Block from "../../components/Block/Block";
import { Icon, MediaGroup, Media, MediaText, Image, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Select } from "../../components";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import { getUserListApi } from "../../services/UserService";
import { Dropdown } from "react-bootstrap";
import { toInitials } from "../../utilities";
import { deptOptions } from "../utilities/Constant";
import { debounce } from "lodash";

function UserListPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [showItemPerPage, setShowItemPerPage] = useState(10);
    const [mobileView, setMobileView] = useState(false);

    const [filter, setFilter] = useState({
        query: "",
        department: "",
        designation: "",
    });
    const recordsPage = 25;
    const navigate = useNavigate();

    const handleShowModal = () => {
        navigate("/add-user");
    };

    // Handle Search with Debouncing
    const handleSearchChange = debounce((value) => {
        CancelRequest();
        setSearchText(value);
        setFilter((prev) => ({
            ...prev,
            query: value.toLowerCase(),
        }));
        setCurrentPage(1);
    }, 100);

    const handleSelectChange = (department) => {
        CancelRequest();
        setSelectedDepartment(department);
        setFilter((prev) => ({
            ...prev,
            query: department.toLowerCase(),
        }));
        setCurrentPage(1);
    };


    // Fetch Data
    const fetchData = useCallback(() => {
        CancelRequest();
        setIsLoading(true);
        getUserListApi(currentPage, recordsPage, { query: filter.query })
            .then((resp) => {
                const allUsers = resp.data.results;
                setData(allUsers);
                setTotal(allUsers.length);
            })
            .catch((error) => {
                console.error(error.response?.data?.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [currentPage, filter.query]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Define columns for your DataTable
    const columns = [
        {
            name: "Users",
            grow: 2,
            selector: (row) => row.name,
            cell: (row) => (
                <MediaGroup>
                    <Media size="md" shape="circle" variant="dark-soft">
                        {row?.profile_photo ? (
                            <Image
                                src={`${process.env.REACT_APP_BASE_URL}${row?.profile_photo}`}
                                staticImage
                                alt="user"
                            />
                        ) : (
                            <span className="fw-medium">{toInitials(row?.name)}</span>
                        )}
                    </Media>

                    <MediaText>
                        <Link to={`/user/${row?.id}`} className="title">
                            <strong>{row?.name}</strong>{" "}
                        </Link>
                        <span className="small text">{row?.email}</span>
                    </MediaText>
                </MediaGroup>
            ),
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row) => row?.designation,
            cell: (row) => (<h6 > {row?.designation}</h6>),
            sortable: true,
        },
        {
            name: "Department",
            selector: (row) => row?.department,
            cell: (row) => row?.department ? (<h6> {deptOptions.find((option) => option.value === row?.department)?.label || "NA"}</h6>) : (
                <h6 > General</h6>
            ),
            sortable: true,
        },
        {
            name: "Reports To",
            selector: (row) => row?.reports_to?.name,
            cell: (row) =>
                row?.reports_to ? (
                    <span>
                        <MediaText>
                            <Link to={`/user/${row?.id}`} className="title">
                                <strong>{row.reports_to?.name}</strong>
                            </Link>
                        </MediaText>
                    </span>
                ) : (
                    "N/A"
                ),
            sortable: true,
        },
        {
            name: "Role",
            selector: (row) => row?.role,
            cell: (row) => (<span className={`badge text-bg-info-soft`}> {row?.role}</span>),
            sortable: false,
        },
        {
            name: "status",
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge text-bg-${row.status === "active" ? "success-soft"
                    : row.status === "blocked" ? "danger-soft"
                        : row.status === "inactive" ? "secondary-soft"
                            : "primary-soft"}`
                }>
                    {row?.status
                        ? row?.status.charAt(0).toUpperCase() + row?.status.slice(1).toLowerCase()
                        : 'General'}
                </span>
            ),
            sortable: false,
        },
        {
            name: "action",
            cell: (row) => (
                <div className="text-end w-100">
                    <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                            <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                    <LinkListItem to={`/edit-user/${row.id}`}>
                                        <Icon name="edit"></Icon><span>Edit</span>
                                    </LinkListItem>

                                    <LinkListItem to={`/user/${row.id}`}>
                                        <Icon name="eye"></Icon><span>View Details</span>
                                    </LinkListItem>
                                </LinkList>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
            sortable: false,
            hide: "md",
        },

    ];

    const viewChange = () => {
        if (window.innerWidth < 960) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    };

    useEffect(() => {
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener("resize", viewChange);
        };
    }, []);

    return (
        <div className="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Users List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">User Management</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add User</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <Block>
                <Card>
                    {/* <DataTable tableClassName="data-table-head-light table-responsive" data={UsersData} columns={userColumns} /> */}
                    <div className="data-table-wrapper">
                        <div className="data-table-top">
                            <div className="data-table-search" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                {/* Search Input */}
                                <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                    <input
                                        className="form-control"
                                        placeholder="Search by any field"
                                        type="text"
                                        value={searchText}
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                        style={{ paddingRight: "30px" }}
                                    />
                                    {/* <Icon
                                        name={searchText ? "times" : "search"}
                                        className={searchText ? "clear-icon" : "search-icon"}
                                        style={{
                                            position: "absolute",
                                            right: "10px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            cursor: "pointer",
                                            color: searchText ? "#999999" : "",
                                        }}
                                        onClick={() => searchText && handleSearchChange("")}
                                    /> */}
                                </div>

                                {/* Department Dropdown */}
                                <select
                                    id="department"
                                    name="department"
                                    value={selectedDepartment}
                                    onChange={(e) => handleSelectChange(e.target.value)}
                                    className="form-select"
                                    style={{ flex: '1' }}
                                >
                                    <option value="">Choose Department</option>
                                    {deptOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>


                            <div className="data-table-action-wrap">
                                <div className="data-table-select">
                                    <select
                                        className="form-select"
                                        onChange={(e) => setShowItemPerPage(e.target.value)}
                                        value={showItemPerPage}
                                    >
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                    </select>
                                    <span className="text">Per page</span>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={data}
                            noDataComponent={
                                <div className="p-2">There are no records found.</div>
                            }
                            sortIcon={<div className="data-table-sorter"></div>}
                            pagination
                            paginationComponent={({
                                rowsPerPage,
                                rowCount,
                                onChangePage,
                                onChangeRowsPerPage,
                                currentPage,
                            }) => (
                                <div className="data-table-bottom">
                                    <DataTablePagination
                                        showItemPerPage={showItemPerPage}
                                        itemPerPage={rowsPerPage}
                                        totalItems={rowCount}
                                        paginate={onChangePage}
                                        currentPage={currentPage}
                                        onChangeRowsPerPage={onChangeRowsPerPage}
                                        setShowItemPerPage={setShowItemPerPage}
                                    />
                                </div>
                            )}
                        />
                    </div>
                </Card>
            </Block>
        </div>
    );
}
export default UserListPage;
